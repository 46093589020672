import React, { useState } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Bicycle from "../assets/img/bicycle.png"
import "./styles/contact.scss"

const ContactPage = () => {
  const [form, setForm] = useState({
    name: "",
    organizationName: "",
    email: "",
    telephone: "",
    country: "",
  })

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    setForm({
      [name]: value,
    })
  }

  return (
    <div class="contactContainer">
      <Header />
      <div class="content">
        <div class="contactText">
          <div class="text">
            <p class="title">Let's talk about everything!</p>
            <p class="subtitle">
              If you don't like this form, send us an email to info@eurotrust.io
            </p>
          </div>
          <img src={Bicycle} alt="Bicycle" />
        </div>
        <div class="contactForm">
          <p class="title">Contact Form</p>
          <form>
            <div class="formGroup">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={form.name}
                onChange={handleInputChange}
              />
            </div>
            <div class="formGroup">
              <input
                type="text"
                name="organizationName"
                placeholder="Organization Name"
                value={form.organizationName}
                onChange={handleInputChange}
              />
            </div>
            <div class="formGroup">
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={form.email}
                onChange={handleInputChange}
              />
            </div>
            <div class="formGroup">
              <input
                type="text"
                name="telephone"
                placeholder="Telephone"
                value={form.telephone}
                onChange={handleInputChange}
              />
            </div>
            <div class="formGroup">
              <input
                type="text"
                name="country"
                placeholder="Country"
                value={form.country}
                onChange={handleInputChange}
              />
            </div>
            <div class="formGroup">
              <button type="button">Send</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactPage
